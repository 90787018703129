import {
  AppBar,
  Toolbar,
  makeStyles,
  Button,
  IconButton,
  Drawer,
  Box,
  Container,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import React, { useState, useEffect, useRef, useContext } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import Logo from "./../../component/Logo";
import { UserContext } from "src/context/User";
import { FaFacebookF, FaInstagram, FaRegMoon, FaTwitter } from "react-icons/fa";
import SettingsContext from "src/context/SettingsContext";
import { AiFillLinkedin } from "react-icons/ai";
import Scroll from "react-scroll";
import { FaRegCircle } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";

const ScrollLink = Scroll.Link;
const useStyles = makeStyles((theme) => ({
  menuButton: {
    fontSize: "14px",
    lineHeight: "24px",
    fontWeight: "400",
    borderRadius: 0,
    minWidth: "auto",
    color: "#fff",
    padding: "0px 25px",
    textDecoration: " none",
    "@media (max-width: 1217px)": {
      width: "100%",
      height: "14px",
      display: "flex",
      padding: "15px 0 !important",
      alignItems: "flex-start",
      justifyContent: "flex-start",
    },
    "&.active": {
      color: "#129DFF",
    },
    "&:hover": {
      color: "#129DFF",
    },
  },
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: 0,
    padding: " 10px 0px",
  },
  maindrawer: {
    height: "100%",
    background: "#0c0731",
    width: "260px",
  },
  logoDrawer: {
    width: "80px",
  },
  drawerContainer: {
    padding: "20px 0px 20px 10px",
    height: "100%",
    background: "#fff",
    background: "#022545",
    width: "200px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
  },
  drawericon: {
    marginLeft: "0px !important",
    fontSize: "25px",
    padding: "0px 0px 0px 16px",
  },
  logoImg: {
    width: "75px",
    margin: " 14px 15px 11px 0px",
    objectFit: "contain",
    "@media (max-width: 500px)": {
      margin: " 11px 1px 3px 0px",
      width: "52px",
      width: "75px",
    },
  },
  inerrMenu: {
    display: "flex",
    listStyle: "none",
    padding: "0",
    whiteSpace: "nowrap",
    paddingLeft: "25px",
    alignItems: "center",
    justifyContent: "flex-start",
    cursor: "pointer",
    "@media (max-width: 1217px)": {
      display: "block",
      padding: "0",
    },
  },
  menuMobile: {
    padding: "16px",
    "@media (max-width: 500px)": {
      padding: "7px 0",
      width: "100%",
    },
  },
  mainHeader: {
    justifyContent: "space-between",
    padding: "7px 0",
  },
  themeButton: {
    background: "#0B1426",
    borderRadius: "34px",
  },
}));

export default function Header() {
  const user = useContext(UserContext);
  const {
    menuButton,
    toolbar,
    drawerContainer,
    menuMobile1,
    drawericon,
    logoDrawer,
    mainHeader,
    inerrMenu,
  } = useStyles();
  const history = useHistory();
  const [state, setState] = useState({
    mobileView: false,
    drawerOpen: false,
  });

  const { mobileView, drawerOpen } = state;
  const themeSeeting = useContext(SettingsContext);
  const changeTheme = (type) => {
    themeSeeting.saveSettings({
      theme: type,
    });
  };

  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 1220
        ? setState((prevState) => ({ ...prevState, mobileView: true }))
        : setState((prevState) => ({ ...prevState, mobileView: false }));
    };

    setResponsiveness();
    window.addEventListener("resize", () => setResponsiveness());
  }, []);

  const [open1, setOpen1] = useState({ community: false, user: false });
  const anchorRef = { community: useRef(null), user: useRef(null) };

  const displayDesktop = () => {
    return (
      <Toolbar className={toolbar}>
        <Box display="flex" justifyContent="flex-start" alignItems="center">
          {femmecubatorLogo}
        </Box>
        <Box display="flex" justifyContent="flex-end" alignItems="center">
          <li className="activecolor" style={{ display: "flex" }}>
            <ul className={inerrMenu}>
              <li>
                {" "}
                <ScrollLink
                  className={menuButton}
                  smooth={true}
                  duration={500}
                  to="home"
                  tabIndex="1"
                  onClick={() => history.push("/?id=home")}
                >
                  {" "}
                  Home{" "}
                </ScrollLink>{" "}
              </li>
              <li>
                {" "}
                <ScrollLink
                  className={menuButton}
                  smooth={true}
                  duration={500}
                  to="buy"
                  tabIndex="3"
                  onClick={() => history.push("/?id=buy")}
                >
                  Features
                </ScrollLink>
              </li>
              <li>
                {" "}
                <ScrollLink
                  className={menuButton}
                  smooth={true}
                  onClick={() => history.push("/about")}
                >
                  {" "}
                  About
                </ScrollLink>{" "}
              </li>
            </ul>
          </li>
          {/* <ScrollLink
            variant="contained"
            color="primary"
            smooth={true}
            duration={500}
            to="features"
            tabIndex="3"
            style={{
              margin: "0px 25px",
              whiteSpace: "pre",
              background: "#1a9eff",
              padding: "10px 30px",
              cursor: "pointer",
              borderRadius: "10px",
              fontSize: "14px",
              color:"#fff",
              fontWeight:"500",
            }}
          >
            Download
          </ScrollLink> */}
          &nbsp;
          <Box className="themeButton">
            <IconButton
              variant="contained"
              className={`searchiconBox1 ${
                themeSeeting?.settings?.theme == "LIGHT" ? "active" : ""
              }`}
              onClick={() => {
                changeTheme("LIGHT");
              }}
            >
              <FaRegCircle />
            </IconButton>

            <IconButton
              variant="contained"
              className={`searchiconBox1 ${
                themeSeeting?.settings?.theme == "DARK" ? "active" : ""
              }`}
              onClick={() => {
                changeTheme("DARK");
              }}
            >
              <FaRegMoon />
            </IconButton>
          </Box>
        </Box>
      </Toolbar>
    );
  };

  const displayMobile = () => {
    const handleDrawerOpen = () =>
      setState((prevState) => ({ ...prevState, drawerOpen: true }));
    const handleDrawerClose = () =>
      setState((prevState) => ({ ...prevState, drawerOpen: false }));
    //mobile
    return (
      <Toolbar className={mainHeader}>
        <Drawer
          {...{
            anchor: "right",
            open: drawerOpen,
            onClose: handleDrawerClose,
          }}
        >
          <div className={drawerContainer}>
            <img className={logoDrawer} src="images/logo.png" alt="" />
            <li className="activecolor" style={{ display: "flex" }}>
              <ul className={inerrMenu}>
                <li>
                  {" "}
                  <ScrollLink
                    className={menuButton}
                    smooth={true}
                    duration={500}
                    to="home"
                    tabIndex="1"
                    onClick={() => history.push("/?id=home")}
                  >
                    {" "}
                    Home{" "}
                  </ScrollLink>
                </li>
                <li>
                  {" "}
                  <ScrollLink
                    className={menuButton}
                    smooth={true}
                    duration={500}
                    to="buy"
                    tabIndex="3"
                    onClick={() => history.push("/?id=buy")}
                  >
                    {" "}
                    Features
                  </ScrollLink>{" "}
                </li>
                <li>
                  {" "}
                  <ScrollLink
                    className={menuButton}
                    smooth={true}
                    // duration={500}
                    // to="features"
                    // tabIndex="3"
                    onClick={() => history.push("/about")}
                  >
                    {" "}
                    About
                  </ScrollLink>{" "}
                </li>
              </ul>
            </li>
            <div>
              {user?.isLogin ? (
                <IconButton
                  aria-label="delete"
                  aria-controls="simple-menu"
                  aria-haspopup="true"
                  className={menuMobile1}
                  size="small"
                  style={{ padding: "16px 0px" }}
                ></IconButton>
              ) : (
                <IconButton
                  aria-label="delete"
                  aria-controls="simple-menu"
                  aria-haspopup="true"
                  className={menuMobile1}
                  size="small"
                >
                  {/* <ScrollLink
                    variant="contained"
                    color="primary"
                    smooth={true}
                    duration={500}
                    to="features"
                    tabIndex="3"
                    style={{
                      whiteSpace: "pre",
                      background: "#1a9eff",
                      padding: "10px 30px",
                      cursor: "pointer",
                      borderRadius: "10px",
                      fontSize: "14px",
                       color:"#fff",
                    }}
                  >
                    Download
                  </ScrollLink> */}

                  <Box></Box>
                </IconButton>
              )}
            </div>
            <Box className="socialButton">
              <IconButton
                target="_blank"
                href="https://twitter.com/chrome_scan"
              >
                <FaXTwitter />
              </IconButton>
              <IconButton
                target="_blank"
                href="https://m.facebook.com/ChromeScanBlockchain/?wtsid=rdr_0kO0lDUbCRIjej1GI"
              >
                <FaFacebookF />
              </IconButton>
              <IconButton target="_blank" href="">
                <FaInstagram />
              </IconButton>
              <IconButton target="_blank" href="">
                <AiFillLinkedin />
              </IconButton>
            </Box>
          </div>
        </Drawer>

        <Box display="flex" justifyContent="space-between">
          {femmecubatorLogo}
        </Box>
        <Box display="flex" alignItems="center">
          <Box className="themeButton">
            <IconButton
              variant="contained"
              className={`searchiconBox1 ${
                themeSeeting?.settings?.theme == "LIGHT" ? "active" : ""
              }`}
              // style={{ background: "rgba(255, 255, 255, 0.05)" }}
              onClick={() => {
                changeTheme("LIGHT");
              }}
            >
              <FaRegCircle />
            </IconButton>

            <IconButton
              variant="contained"
              // className="searchiconBox1"
              className={`searchiconBox1 ${
                themeSeeting?.settings?.theme == "DARK" ? "active" : ""
              }`}
              // style={{ background: "rgba(13, 13, 13, 0.08)" }}
              onClick={() => {
                changeTheme("DARK");
              }}
            >
              <FaRegMoon />
            </IconButton>
          </Box>
          <IconButton
            className={drawericon}
            {...{
              edge: "start",
              color: "inherit",
              "aria-label": "menu",
              "aria-haspopup": "true",
              onClick: handleDrawerOpen,
            }}
          >
            <MenuIcon
              width="60px"
              height="60px"
              style={{ color: "#fff", fontSize: "26px" }}
            />
          </IconButton>
        </Box>
      </Toolbar>
    );
  };

  const femmecubatorLogo = (
    <Box>
      <Link to="/">
        <Logo className="logoImg" style={{ width: "110px" }} />
      </Link>
    </Box>
  );

  return (
    <>
      <AppBar
        position={history.location.pathname !== "/" ? "relative" : "absolute"}
        elevation={0}
        style={{ backgroundColor: "transparent", border: "none" }}
      >
        <Container maxWidth="lg">
          {mobileView ? displayMobile() : displayDesktop()}
        </Container>
      </AppBar>
    </>
  );
}
