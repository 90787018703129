import React, { useContext } from "react";
import {
  Grid,
  Box,
  Container,
  Typography,
  makeStyles,
  ListItem,
  List,
  Link,
  IconButton,
  Divider,
} from "@material-ui/core";
import SettingsContext from "src/context/SettingsContext";
import { useHistory, Link as RouterLink } from "react-router-dom";
import { useWeb3React } from "@web3-react/core";
import { Link as ScrollLink } from "react-scroll";
import { FaFacebookF, FaInstagram, FaLinkedin} from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { AiFillLinkedin, AiOutlineInstagram } from "react-icons/ai";

const useStyles = makeStyles((theme) => ({
  footerSection: {
    position: "relative",
    padding: "30px 0px 0",
    zIndex: "2",
    overflow: " hidden",
    background: "#0B1426",
    "& .footerContentBox": {
      maxWidth: "260px",
      "& img": {
        width: "150px",
        [theme.breakpoints.down("xs")]: {
          width: "100px",
        },
      },
    },
    "& ul": {
      padding: "0px",
      "& li": {
        paddingLeft: "0",
        alignItems: "center",
        color: "#fff",
        cursor: "pointer",
        fontSize: "14px",
        fontWeight: "400",
        [theme.breakpoints.down("sm")]: {
          fontSize: "12px",
        },
      },
    },
    "& p": {
      color: "white",
      fontSize: "14px",
      fontWeight: 400,
    },
    "& h6": {
      [theme.breakpoints.down("sm")]: {
        marginTop: "30px",
      },
      [theme.breakpoints.down("xs")]: {
        marginTop: "10px",
      },
    },

    "& a": {
      color: "white",
      textDecoration: "none",
      display: "flex",
      fontSize: "14px",
      fontWeight: 400,
      width: "fit-content",
      alignItems: "center",
      paddingLeft: "0px",
      paddingRight: "0px",
      [theme.breakpoints.only("xs")]: {
        fontSize: "11px",
      },
    },
    "& .MuiDivider-root": {
      backgroundColor: "#ffffff6b",
      marginTop: "25px",
    },
  },
  iconbtn: {
    display: "flex",
    gap: "15px",
    cursor: "pointer",
    marginTop: "15px",
    "& .MuiIconButton-root": {
      borderRadius: "8px",
      background: "white",
      padding: "10px",
      [theme.breakpoints.down("xs")]: {
        marginBottom: "10px",
        padding: "5px",
      },
      "& svg":{
        color:"#000",
        fontSize:"20px"
      }
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: "5px",
      marginBottom: "10px",
    },
  },
}));

export default function Footer() {
  const classes = useStyles();
  const history = useHistory();
  const themeSeeting = useContext(SettingsContext);
  // const user = useContext(UserContext)
  const { account, library, chainId } = useWeb3React();

  return (
    <>
      <Box className={classes.footerSection}>
        <Container maxWidth="lg">
          <Grid container justifyContent="space-between">
            <Grid item xs={12} sm={6} md={3}>
              <Box style={{}} className="footerContentBox">
                <Box mb={2}>
                  {" "}
                  <RouterLink to="/">
                    <img src="images/logo.png" alt="" /> <br />
                  </RouterLink>
                </Box>
              </Box>
            </Grid>

            <Grid item xs={6} sm={6} md={2}>
              <List>
              <ScrollLink
                to="home"
                spy={true}
                smooth={true}
                duration={500}
                onClick={() => history.push("/?id=home")}
              >
                <ListItem tabIndex="3">
                  Home
                </ListItem>
                </ScrollLink>
                <ScrollLink
                to="buy"
                spy={true}
                smooth={true}
                duration={500}
                onClick={() => history.push("/?id=buy")}
              >
                <ListItem tabIndex="3">
                  Features
                </ListItem>
              </ScrollLink>
                <ListItem to="/about" component={RouterLink}>
                  About Us
                </ListItem>
                {/* <ListItem to="/about" component={RouterLink}>
                  Download
                </ListItem> */}
              </List>
            </Grid>
            <Grid item xs={6} sm={6} md={3}>
              <List>
                <ListItem
                  to="/privacy-policy"
                  component={RouterLink}
                  style={{ whiteSpace: "pre" }}
                >
                  Privacy Policy
                </ListItem>

                <ListItem to="/terms-conditions" component={RouterLink}>
                  Terms & Conditions
                </ListItem>
                {/* <ListItem to="/about" component={RouterLink}>
                  Contributor License Agreement
                </ListItem> */}
              </List>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Box className={classes.iconbtn}>
                <IconButton>
                  <Link target="_blank" href="https://twitter.com/chrome_scan">
                  <FaXTwitter />
                  </Link>
                </IconButton>
                <IconButton>
                  <Link target="_blank" href="https://m.facebook.com/ChromeScanBlockchain/?wtsid=rdr_0kO0lDUbCRIjej1GI">
                  <FaFacebookF />
                  </Link>
                </IconButton>
                <IconButton>
                  <Link target="_blank" href="">
                  <FaInstagram/>
                  </Link>
                </IconButton>
                <IconButton>
                  <Link target="_blank" href="">
                  <AiFillLinkedin />
                  </Link>
                </IconButton>
              </Box>
              <Box className={classes.iconbtn}>
                <Link
                  target="_blank"
                  href="https://apps.apple.com/us/app/cscan-wallet/id6469110992"
                >
                  <img src="images/App_store.svg" alt="App Store" />
                </Link>
                <Link
                  href="https://play.google.com/store/apps/details?id=com.cscanwallet&pli=1"
                  target="_blank"
                >
                  <img src="images/play_store.png" alt="Play Store" />
                </Link>
              </Box>
            </Grid>
          </Grid>
          <Divider />
        </Container>
        <Box className="copy" mt={1}>
          <Container>
            <Box
              alignItems="center"
              my={2}
              position="relative"
              flexWrap="wrap"
              display="flex"
              justifyContent="center"
            >
              <Typography>Copyright© CSCAN 2023</Typography>
            </Box>
          </Container>
        </Box>
      </Box>
    </>
  );
}
