import _ from "lodash";
import { colors, createMuiTheme, responsiveFontSizes } from "@material-ui/core";

import typography from "./typography";

const baseOptions = {
  direction: "ltr",
  typography,
  overrides: {
    MuiTableCell: {
      root: {
        padding: "7px",
        fontSize: "12px",
        borderBottom: "none",
      },
      head: {
        fontWeight: "200",
        fontSize: "14px",
      },
    },
    MuiInputAdornment: {
      positionStart: {
        paddingLeft: "14px",
      },
    },

    MuiButton: {
      root: {
        "&.Mui-disabled": {
          color: "rgb(112, 107, 107)",
        },
      },
      contained: {
        fontSize: "14px !important",
        fontWeight: "300",
        borderRadius: "5px",
        whiteSpace: "pre",
        padding: "10px 20px",
      },
      outlined: {
        fontSize: "14px !important",
        fontWeight: "300",
        borderRadius: "50px",
        whiteSpace: "pre",
        padding: "10px 20px",
      },
      outlinedSizeLarge: {
        padding: "7px 35px",
      },
      containedSizeLarge: {},
    },
  },
};

const themesOptions = [
  {
    name: "LIGHT",
    overrides: {
      MuiPaper: {
        root: {
          backgroundColor: "#050404",
        },
      },
      MuiTableCell: {
        body: {
          color: "#000",
        },
      },
      MuiMenu: {
        list: {
          outline: "0",
          background: "#ffffff",
        },
      },
      MuiButton: {
        containedPrimary: {
          color: "#fff",
          filter: "drop-shadow(0px 13px 27px rgba(0, 0, 0, 0.25))",
          padding: "12px 35px",
          fontSize: "14px",
          background: "#129DFF !important",
          fontWeight: "500",
          lineHeight: "21px",
          borderRadius: "10px",
          backgroundColor: "#898989",

          "&:hover": {
            color: "#000",
            background: "transparent",
            boxShadow: "none",
          },
        },
 
        contained: {
          "&.Mui-disabled": {
            backgroundColor: "rgba(0, 0, 0, 0.03) ",
          },
        },
      },
      MuiIconButton: {
        root: {
          color: "#000000",
        },
      },
    },
    palette: {
      background: {
        sellercard: "#fff",
        cardinner: "#022050",
        card: "#F7F7F7",
        default: "#cccccc",
        subscribe:'#EFF2F6'
      },
      primary: {
        main: "#0B1426", //black
      },
      secondary: {
        main: "#000000", //black
        icons: "#009900", //white
      },
      text: {
        primary: "#000", //black
        secondary: "#181818", //white
        gray: "rgba(0, 0, 0, 0.6)",
        graydark: "rgba(13, 13, 13, 0.3)",
      },
    },
  },
  {
    name: "DARK",
    overrides: {
      MuiPaper: {
        root: {
          backgroundColor: "#050404",
        },
      },
      MuiTableCell: {
        body: {
          color: "rgba(255, 255, 255, 0.5)",
        },
      },
      MuiMenu: {
        list: {
          outline: "0",
          background: "#191919",
        },
      },
      MuiButton: {
        containedPrimary: {
          color: "#fff",
          filter: "drop-shadow(0px 13px 27px rgba(0, 0, 0, 0.25))",
          padding: "12px 35px",
          fontSize: "14px",
          background: "#129DFF !important",
          fontWeight: "500",
          lineHeight: "21px",
          borderRadius: "10px",
          backgroundColor: "#898989",
          "&:hover": {
            color: "#ffffff",
            background: "transparent",
            boxShadow: "none",
            backgroundColor: "transparent",
          },
        },
  
        contained: {
          "&.Mui-disabled": {
            backgroundColor: "rgba(255, 255, 255, 0.025) ",
            color: "#ffffff45",
          },
        },
      },
      MuiIconButton: {
        root: {
          color: "#fff",
        },
      },
    },
    palette: {
      background: {
        sellercard: "#0B1426",
        card: "rgba(255, 255, 255, 0.02)",
        cardinner: "#032352",
        default: "#000000",
        subscribe:'#0A172D'
      },
      primary: {
        main: "#ffffff", //black
      },
      secondary: {
        main: "#FFFFFF", //white
        icons: "#FFFFFF", //white
      },
      text: {
        primary: "#FFFFFF", //white
        secondary: "#DADADA", //white
        gray: "rgba(255, 255, 255, 0.6)",
        graydark: "rgba(255, 255, 255, 0.3)",
      },
    },
  },
];

export const createTheme = (config = {}) => {
  let themeOptions = themesOptions.find((theme) => theme.name === config.theme);

  if (!themeOptions) {
    console.warn(new Error(`The theme ${config.theme} is not valid`));
    [themeOptions] = themesOptions;
  }

  let theme = createMuiTheme(
    _.merge({}, baseOptions, themeOptions, { direction: config.direction })
  );

  if (config.responsiveFontSizes) {
    theme = responsiveFontSizes(theme);
  }

  return theme;
};
